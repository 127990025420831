import { Button, Skeleton, Stack, Typography } from '@mui/joy';
import { MainWrapper } from 'elements/MainWrapper';
import { PageHeaderLoadingSkeleton } from 'elements/PageHeaderLoadingSkeleton';

export const SubmitSurveyLoadingSkeleton = () => {
  return (
    <>
      <PageHeaderLoadingSkeleton />
      <MainWrapper className={'app__centered'}>
        <Stack
          component={'section'}
          spacing={5}
          data-component={'submit-survey'}
        >
          <Typography
            level={'h1'}
            textAlign={'center'}
          >
            <Skeleton>Are you ready to submit your survey?</Skeleton>
          </Typography>
          <Stack spacing={0.5}>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              <Skeleton>You've reached the last question in this survey.</Skeleton>
            </Typography>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              <Skeleton>You can choose to go back and edit any of your answers.</Skeleton>
            </Typography>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              <Skeleton>Or, you can submit your survey.</Skeleton>
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            justifyContent={'center'}
          >
            <Button
              size={'lg'}
              color={'neutral'}
              variant={'outlined'}
            >
              Back to survey
              <Skeleton />
            </Button>
            <Button
              size={'lg'}
            >
              Submit survey
              <Skeleton />
            </Button>
          </Stack>
        </Stack>
      </MainWrapper>
    </>
  );
};
