import { Button, Stack, Typography } from '@mui/joy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MainWrapper } from 'elements/MainWrapper';
import { PageHeader } from 'elements/PageHeader';
import { useLeavePageDialog, useLocale, useNavigateKeepParams } from 'hooks';

import { useQuestionsApi } from '../useQuestionsApi';
import { SubmitSurveyLoadingSkeleton } from './SubmitSurveyLoadingSkeleton';

export const SubmitSurvey = () => {
  const { feedbackID } = useParams();
  const navigateKeepParams = useNavigateKeepParams();
  const { t } = useTranslation();
  const [locale] = useLocale();

  const {
    loading,
    submitLoading,
    onSubmit: onSubmitApi,
    error
  } = useQuestionsApi({ feedbackID });

  const onSubmit = useCallback(
    () => onSubmitApi(),
    [onSubmitApi]
  );

  const backToQuestions = useCallback(
    () => {
      navigateKeepParams(`/${feedbackID}/questions`);
    },
    [navigateKeepParams, feedbackID]
  );

  useLeavePageDialog();

  if (loading) return <SubmitSurveyLoadingSkeleton />;

  return (
    <>
      <PageHeader
        hideBackToWelcomePage
        hideSave
      />
      <MainWrapper className={'app__centered'}>
        <Stack
          component={'section'}
          spacing={5}
          data-component={'submit-survey'}
        >
          <Typography
            level={'h1'}
            textAlign={'center'}
          >
            {t('Are you ready to submit your survey?')}
          </Typography>
          <Stack spacing={0.5}>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              {t("You've reached the last question in this survey.")}
            </Typography>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              {t('You can choose to go back and edit any of your answers.')}
            </Typography>
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
            >
              {t('Or, you can submit your survey.')}
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            justifyContent={'center'}
          >
            <Button
              size={'lg'}
              onClick={backToQuestions}
              color={'neutral'}
              variant={'outlined'}
              data-cy={'back-to-survey-button'}
            >
              {t('Back to survey')}
            </Button>
            <Button
              size={'lg'}
              onClick={onSubmit}
              loadingPosition={'end'}
              loading={submitLoading}
              data-cy={'submit-survey-button'}
            >
              {t('Submit survey')}
            </Button>
          </Stack>

          {error && (
            <Typography
              textAlign={'center'}
              fontSize={{ xs: 'sm', md: 'md' }}
              color={'danger'}
            >
              {error?.[locale]}
            </Typography>
          )}
        </Stack>
      </MainWrapper>
    </>
  );
};

export default SubmitSurvey;
